import styled from '@emotion/styled';
import { colors, textColors } from '../utils/color-palette';
import { rhythm } from '../utils/typography';
import { Link } from 'gatsby-plugin-react-intl';
import { css } from '@emotion/core';

const buttonTextStyles = css`
  background: transparent;
  border: none;
  margin: 0 ${rhythm(0.5)};
  height: ${rhythm(1)};

  cursor: pointer;
  color: ${colors.primary};

  :hover {
    color: ${colors.specialNav};
  }

    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    text-decoration: underline ${colors.specialNav};
  }
  
  &:last-of-type {
  margin-right: 0;
  }
`;

export const ButtonText = styled.button<{ active?: boolean }>`
  ${buttonTextStyles};

  text-decoration: ${props =>
    props.active ? `underline ${colors.specialNav}` : 'none'};
`;
export const ButtonLink = styled(Link)(buttonTextStyles);
export const ButtonA = styled.a(buttonTextStyles);

export const Button = styled.button`
  background: none;
  border: 2px solid ${colors.primaryLight};
  border-radius: 30px;

  height: ${rhythm(2)};
  cursor: pointer;
  color: ${colors.primary};

  :hover {
    background: rgba(179, 223, 240, 1);
    color: ${textColors.special};
    border-color: rgba(179, 223, 240, 1);
  }

  a {
    padding: 0 ${rhythm(1)};
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;
